/**
 * Standard definitions for University of Toronto applications.
 */

/* Application Header */
.header {
    background: #013A6F;
    width: 100%;
    padding-left: 40px;

    & .header-wrapper {
        margin: 0px auto;
        padding: 2rem 0;

        h1 {
            font-size: 2.8rem;
            font-weight: 500;
            color: white;
            vertical-align: top;
            margin: 3rem 0 0 3rem;
            display: inline-block;
            background: transparent;
        }

        & .header-logo {
            border-right: 1px solid #CCC;
            vertical-align: baseline;
            max-width: 25rem;
            max-height: 8.4rem;  /*the height of the svg logo is 84px and it looks funky on IE without max-width*/
            padding: 0 3rem 0 0;
        }
    }
}

a.footerLink {
    color: #0955B2;
    font-size: 13px;
    font-weight: normal;
}

a.footerLink:visited, a.footerLink:hover{
    color:#003F8C;
}

.fa-small {
    font-size: 1.2rem !important;
}

/*this represents tablets and smaller devices*/

@media screen and (max-width: 768px) {

    .header .header-wrapper {
        h1 {
            margin: 2rem 0 0 2rem;
            font-size: 2.4rem;
        }

        & .header-logo {
            max-width: 20rem;
        }
    }
}

/*these media queries are for when the header wraps in responsive view*/

@media screen and (max-width: 566px) {

    .header .header-wrapper {
        h1 {
            margin: 2rem 0 0 0;
        }

        & .header-logo {
            border-right: none;
        }
    }
}

/*wide-screen responsive adjustments*/

@media screen and (min-width: 1366px) {

    .header .header-wrapper {
        max-width: 1366px;
        margin-left: auto;
        margin-right: auto;
        background: #013a6f;
    }
}
