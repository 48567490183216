/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */
$navbar-inverse-link-color: #5AADBB;
$icon-font-path: "../../bower_components/bootstrap-sass/assets/fonts/bootstrap/";

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "../../bower_components/font-awesome/scss/font-awesome.scss";
// endbower

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "sass/financialPlanner.scss";
@import "sass/loadOver.scss";
@import "sass/utStandardUx.scss";
// endinjector
