/**
 * Styling to create the loadover input inhibitor and spinner
 *
 * The following CSS is used to provide an overlay that inhibits user input while a long running process is executing.
 * The spinner size and speed may be adjusted.  See utLoading.js.  See http://cssload.net/en/spinners for a collection
 * of CSS spinner controls.
 *
 * Also see http://fontawesome.io/test/ which provides a spinner with much less effort.  The trouble is, a known bug
 * causes the spinner to wobble, which I found unacceptable.
 */

// Variables
$loadover_speed: 0.30;
$loadover_base_speed: 3 * $loadover_speed;
// Diameter of each circle
$loadover_diameter: 14;
// Overall box that contains the spinner
$loadover_square: (4.25 * $loadover_diameter);
// Offset from the box edge for the N, S, E, W circles
$loadover_mid: (1.6 * $loadover_diameter) + px;
// Offset from the box corner for the NE, SE, SW, NW circles
$loadover_offset: (0.4 * $loadover_diameter) + px;
// Radius of each individual circle
$loadover_radius: 2 + (0.5 * $loadover_diameter) + px;

/**
 * Add a setting for all the various browser types.
 *
 * @param  $suffix A suffix to identify the CSS3 setting
 * @param  $value  The value to be applied to all settings
 */
@mixin loadover-settings ($suffix, $value) {
    #{$suffix}: $value;
    -o-#{$suffix}: $value;
    -ms-#{$suffix}: $value;
    -webkit-#{$suffix}: $value;
    -moz-#{$suffix}: $value;
}

/**
 * Add keyframes settings for all browser types.  This mixin requires content.
 *
 * @param  $animationName Identifies the animation
 */
@mixin loadover-keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        $browser: '-webkit-' !global;
        @content;
    }
    @-moz-keyframes #{$animationName} {
        $browser: '-moz-' !global;
        @content;
    }
    @-o-keyframes #{$animationName} {
        $browser: '-o-' !global;
        @content;
    }
    @keyframes #{$animationName} {
        $browser: '' !global;
        @content;
    }
} $browser: null;

@media screen {

    .loadOver {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 4 + $loadover_square + px;
        height: 4 + $loadover_square + px;
        margin-top: -4 - $loadover_square + px;
        margin-left: -4 - $loadover_square + px;
        overflow: hidden;
        z-index: 9999;
    }

    .loadOverDiv {
        position: relative;
        width: $loadover_square + px;
        height: $loadover_square + px;
        margin: auto;

        .circularG {
            position: absolute;
            background-color: rgb(0,0,0);
            width: $loadover_diameter + px;
            height: $loadover_diameter + px;
            @include loadover-settings(border-radius, $loadover_radius);
            @include loadover-settings(animation-name, bounce_circularG);
            @include loadover-settings(animation-duration, $loadover_base_speed + (5 * $loadover_speed) + s);
            @include loadover-settings(animation-iteration-count, infinite);
            @include loadover-settings(animation-direction, normal);
        }

        // West
        #circularG_1 {
            left: 0;
            top: $loadover_mid;
            @include loadover-settings(animation-delay, $loadover_base_speed + s);
        }

        // North-West
        #circularG_2 {
            left: $loadover_offset;
            top: $loadover_offset;
            @include loadover-settings(animation-delay, $loadover_base_speed + $loadover_speed + s);
        }

        // North
        #circularG_3 {
            top: 0;
            left: $loadover_mid;
            @include loadover-settings(animation-delay, $loadover_base_speed + (2 * $loadover_speed) + s);
        }

        // North-East
        #circularG_4 {
            right: $loadover_offset;
            top: $loadover_offset;
            @include loadover-settings(animation-delay, $loadover_base_speed + (3 * $loadover_speed) + s);
        }

        // East
        #circularG_5 {
            right: 0;
            top: $loadover_mid;
            @include loadover-settings(animation-delay, $loadover_base_speed + (4 * $loadover_speed) + s);
        }

        // South-East
        #circularG_6 {
            right: $loadover_offset;
            bottom: $loadover_offset;
            @include loadover-settings(animation-delay, $loadover_base_speed + (5 * $loadover_speed) + s);
        }

        // South
        #circularG_7 {
            left: $loadover_mid;
            bottom: 0;
            @include loadover-settings(animation-delay, $loadover_base_speed + (6 * $loadover_speed) + s);
        }

        // South-West
        #circularG_8 {
            left: $loadover_offset;
            bottom: $loadover_offset;
            @include loadover-settings(animation-delay, $loadover_base_speed + (7 * $loadover_speed) + s);
        }

        @include loadover-keyframes(bounce_circularG) {
           0% {
               #{$browser}transform: scale(1);
           }
           100% {
               #{$browser}transform: scale(.3);
           }
        }
    }

    .loadOverOverlay {
        z-index: 9999;
        display: block;
        background-color: #777;
        opacity: 0.7;
        filter: alpha(opacity = 70);
        cursor: wait;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100%;
    }

}
