$data-entry-width: 40rem;
$data-padding: 2rem;

@media screen {
    .container-fluid {
        padding-right: $data-padding;
        padding-left: $data-padding;
    }

    div.buttons {
        padding: $data-padding 0 $data-padding 0;
    }

    /**
     * FNPLCAL-54 Apply animation so display and hide of nested input sections happens smoothly.  Requires ngAnimate to be
     * defined in the app.
     */
    .data_table_transition {
        &.ng-hide-add, &.ng-hide-remove {
            transition: all linear 0.5s;
            -webkit-transition: all linear 0.5s;
            -moz-transition: all linear 0.5s;
            -o-transition: all linear 0.5s;
            -ms-transition: all linear 0.5s;
        }

        &.ng-hide {
            line-height: 0;
            opacity: 0;
        }
    }

    // If a suggestion box comes back with no matches, we want to display a small box to notify the user
    span.noResults {
        display: block;

        & .dropdown-menu {
            max-height: 20rem;
            overflow: auto;
            cursor: default;

            & li:active a:active,
            & li:hover a:hover,
            & li:focus a:focus,
            & > .active > a {
                background-color: #EEEEEE;
                color: #404040;
            }
        }

        /*the width is 90% instead of 30rem because on phone screens larger than iphone5, the dropdown looks far too small.*/
        & [uib-typeahead-popup].dropdown-menu { width: 90%; }
    }

    .dropdown-menu {
        max-height: 35rem;
        overflow: auto;
        cursor: default;
    }

    a.linkPointer, div.linkPointer, i.linkPointer {
        cursor: pointer;

        &:hover {
            cursor: pointer;
        }
    }

    .residence-header {
        background: bisque;

        & input {
            background: blanchedAlmond;
        }
    }

    .residenceMember, .facultyMember {
        cursor: move;
    }

    .fullWidth {
        width: 100%;
    }

    .richTable {
        width: 100%;

        & .richTableHeader {
            position: relative;
            border-width: 0px;
            text-align: left;
            background: #79818C;
            padding: 7px 5px;
            color: white;
        }

        & .richTableHeaderCell {
            color: white;
            font-weight: bold;

            & a, & a:link, & a:hover, & a:focus {
                padding-left: 4px;
                color: white;
                font-weight: normal;
            }
        }
    }

    /**
     * Styling for the uib typeahead control input field.  We need to add a visible indicator that a dropdown list exists,
     * which we do by showing an icon in the background.  The default colouring is a bit different from the other (select)
     * entry fields on this page so we do some styling to make this control blend in better.
     *
     * Note that when running through gulp we use this file directly, but we minimise it for production use.  The images
     * directory path in production is only one directory back relative to the current path, but with gulp it's two
     * directories back.  The gulp build process changes the path appropriately.
     */
    .financialPlannerApp {
        & .typeAhead {
            width: $data-entry-width;
           /*background-color: white;
            background-image: url('../../app/images/fa-caret-down.svg');
            background-size: $data-padding;
            background-repeat: no-repeat;
            background-position: 100%;*/
            border-color: rgb(169,169,169);
            border-width: thin;
            padding-left: 5px;

            &.errorMsg {
                border-color: #cc3835;
            }

        }

        & .typeAheadHeading {
            padding-left: 0;
        }

        & .dropdown-header {
            color: #404040;
            font-size: 1.4rem;
            font-weight: bold;
            cursor: auto;
        }

        & .active .dropdown-header {
            color: #1a1a1a;
        }

        & .typeAheadModel {
            padding-left: $data-padding;
        }

        /**
         * Our typeahead control uses a custom template to display dollar cost amounts (if available) on the right of the
         * selection list.  We want to style them slightly differently as a visual indicator that you can't select on the cost.
         */
        & .dollarDisplay {
            // color: #757575;
            float: right;
            padding-left: 50px;
            padding-right: 0px;
        }

        /**
         * A problem was seen in Firefox where the residence name and cost were displayed on separate lines.  This was
         * tracked down to the dropdown-menu class which disables wrapping.  This class is used to enable the wrapping,
         * which paradoxically stops the output separation.
         */
        & .normalWrap {
            white-space: normal;
        }

        input.label-list {
            line-height: 1.4rem;
            font-size: 1.4rem;
            border: 1px solid #cccccc;
            border-radius: 5px;
            width: $data-entry-width;
            height: 3.5rem;
            // background: white;
            -webkit-box-shadow: none;
            padding: 0 1rem 0 1rem;
            color: #404040;
            margin-top: 1rem;
        }

        & .fa-external-link {
            font-size: 1.4rem !important;
        }
    }

    .modal-body {
        font-size: 14px;
    }

    // DXNG-89 tooltips are showing behind modal window in QA
    .tooltip {
        z-index: 1151 !important;
    }

    .tooltip-inner {
        text-align:left;
        max-width: 350px;
        color: #f2f2f2;
        padding: 1rem 1rem 1rem 1rem;
    }

    .tooltip_link {
        border-bottom: 1px dotted #FFF;

        &, &:hover {
            cursor: help;
        }
    }

    .tooltip_link_dark {
        border-bottom: 1px dotted #1A1A1A;

        &, &:hover {
            cursor: help;
        }
    }

    /* Common markup for budget information boxes */
    section.budget {
        width: 100%;
        margin: auto;
        padding: 0;
    }

    .budgetBox {
        padding: $data-padding;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
    }

    .budgetTip {
        color: #2a4654;
        background: #abe3ff;
        border: 1px solid #52bdf2;
        margin-bottom: $data-padding;
    }

    .budgetWidth {
        width: $data-entry-width;
        display: flex;
        margin-top: $data-padding;
    }

    .budgetDeficit {
        color : #542A2A;
        background: #f2a2a2;
        border: 1px solid #e54e4e;

        & h4 {
            color: #542A2A;
            margin: 0 0 1em 0;
        }
    }

    .budgetSurplus {
        color: #17400F;
        background: #acd9a3;
        border: 1px solid #3ea629;

        & h4 {
           color: #17400F;
           margin: 0 0 1em 0;
        }
    }

    .budgetChart {
        /* Remove vertical grid lines */
        & .nv-axis.nv-x .tick line {
            display: none;
        }

        & .nvd3 {
            /* Remove the vertical line at the left edge of the chart */
            & .nv-axis.nv-y path.domain {
                stroke-opacity: 0;
            }

            /* Horizontal grid line colouring */
            & .nv-axis line {
                stroke: #cccccc;
            }

            /* The zero horizontal line should be slightly darker */
            & .nv-zeroLine line {
                stroke: #000;
            }

            & .nv-group {
                & .negative text,
                & .positive text {
                    stroke: none;
                    font-size: 1.4rem;
                }

                & .positive text {
                    stroke: #17400f;
                }

                & .negative text {
                    stroke: #542a2a;
                }
            }
        }
    }

    a.feedback-mail-button {
        margin-bottom: 2rem;
    }

    /**
     * Feedback button, displayed at the footer of all student pages.
     */
    a.feedback-button {
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;
        color: black;
        text-shadow: 0px 1px 0px white;
        background: #FFF;
        background: -moz-linear-gradient(top, #f0f0f0 0%, #e6e6e6 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f0f0f0), color-stop(100%, #e6e6e6)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  #f0f0f0 0%,#e6e6e6 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top, #f0f0f0 0%, #e6e6e6 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top, #f0f0f0 0%, #e6e6e6 100%); /* IE10+ */
        background: linear-gradient(top, #f0f0f0 0%, #e6e6e6 100%); /* W3C */
        background-image: url('../../app/images/quote.png');
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f0f0', endColorstr='#e6e6e6', GradientType=0);  /* IE6-9 */
        border: solid 1px #b4b4b4;
        margin-top: 10px;
        padding: 6px 11px 8px 31px;
        background-repeat: no-repeat;
        background-position: 6px;

        &:hover, &:active, &:focus, &:visited {
            color: black;
            text-decoration: none;
            outline: none;
        }
    }

    // Feedback dialog input fields
    input.feedback, select.feedback {
        box-sizing: border-box;
        width: inherit;
        &[disabled] {
            border: 1px solid #F1F1F1;
            background-color: rgb(235, 235, 228);
            color: #999;
        }
    }

    // Feedback dialog message entry
    textarea.feedback {
        box-sizing: border-box;
        width: inherit;
        height: 80px;
    }
}

/*this is so any mobile devices any smaller than an iPad will wrap datalist entries to a new line.
The iPad has sufficient space to not require this wrapping*/
@media screen and (max-width: 767px) {

    span.noResults .dropdown-menu > li > a { white-space: normal; }

    .dropdown-menu {
        max-height: 20rem;
        overflow: auto;
    }

    .financialPlannerApp {

        & .typeAheadModel {
            padding-left: 1rem;
            display: inline-block;
        }

        & .dropdown-header {
            color: #404040;
            font-size: 1.3rem;
            font-weight: 600;
        }

        input.label-list {
            width: 100%;
            padding: 0 0 0 1rem;
            border-radius: 5px;
        }
    }

}
